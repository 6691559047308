import { FormField, Select } from 'grommet';

import { countries } from '../../lib';

interface Props {
  disabled?: boolean;
}

const options = countries;
export const CountryField = (props: Props) => {
  return (
    <FormField name='country' htmlFor='countryField' label='Country'>
      <Select
        id='countryField'
        name='country'
        placeholder='Choose country'
        disabled={props.disabled}
        options={options}
        labelKey='label'
        valueKey={{ key: 'id', reduce: true }}
      />
    </FormField>
  );
};
