import { Grid, Box, ResponsiveContext } from 'grommet';

interface Props {
  image: React.ReactNode;
  text: React.ReactNode;
  flip?: boolean;
}
export const ImageAndDescriptionGrid = (props: Props) => {
  return (
    <ResponsiveContext.Consumer>
      {(responsive) =>
        responsive === 'small' ? (
          <Grid
            rows={['flex', 'flex']}
            columns={['flex']}
            gap='small'
            areas={[
              { name: 'text', start: [0, 0], end: [0, 0] },
              { name: 'image', start: [0, 1], end: [0, 1] },
            ]}
          >
            <Box gridArea='image'>{props.image}</Box>
            <Box gridArea='text'>{props.text}</Box>
          </Grid>
        ) : (
          <Grid
            rows={['medium']}
            columns={props.flip ? ['flex', '1/3'] : ['1/3', 'flex']}
            gap='medium'
            areas={
              props.flip
                ? [
                    { name: 'text', start: [0, 0], end: [0, 0] },
                    { name: 'image', start: [1, 0], end: [1, 0] },
                  ]
                : [
                    { name: 'image', start: [0, 0], end: [0, 0] },
                    { name: 'text', start: [1, 0], end: [1, 0] },
                  ]
            }
          >
            <Box gridArea='image'>{props.image}</Box>
            <Box gridArea='text'>{props.text}</Box>
          </Grid>
        )
      }
    </ResponsiveContext.Consumer>
  );
};
