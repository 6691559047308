import { Session } from '@supabase/supabase-js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from './auth-context';
import { supabase } from '../supabase';

interface Props {
  children: React.ReactNode;
}

export const AuthProvider = (props: Props) => {
  const [session, setSession] = useState<Session | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setLoading(false);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setLoading(false);
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        session,
        logout: async () => {
          // TODO: what to do with result.error
          await supabase.auth.signOut();
          // Manually call setSession incase supabase call fails (e.g user is logged in, but i delete them via supabase UI)
          setSession(null);
          navigate('/');
        },
      }}
    >
      {loading ? null : props.children}
    </AuthContext.Provider>
  );
};
