import { createContext } from 'react';

import { Tables } from '../supabase';

interface SubscriptionContextProps {
  subscription: Tables<'stripe_subscriptions'> | null;
  readonly subscriptionActive: boolean;
  setSubscription: (newSubscription: Tables<'stripe_subscriptions'> | null) => void;
}
export const SubscriptionContext = createContext<SubscriptionContextProps>({
  subscription: null,
  subscriptionActive: false,
  setSubscription: () => {},
});
