import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from './use-auth';
import { RoutePaths } from '../routes';
import { useRedirectHashParams } from '../supabase';

interface Props {
  children: React.ReactElement;
}
export const AuthGuard = (props: Props) => {
  const { session } = useAuth();
  const location = useLocation();
  const { error_description } = useRedirectHashParams();

  // if session, render children
  // if error hash, navigate to link expired page (example is clicking on password reset email link for the second time)
  // else, navigate to login page

  return session ? (
    props.children
  ) : error_description ? (
    <Navigate to={RoutePaths.linkExpired} replace />
  ) : (
    <Navigate
      to={RoutePaths.login}
      replace
      state={{ from: location }} // to redirect back to target url
    />
  );
};
