import { Box, Grommet, Page } from 'grommet';
import { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AppHeader, AppFooter } from './components';
import { AuthGuard, DisallowAuthGuard, RoutePaths, SubscriptionProvider } from './lib';
import { AuthProvider } from './lib/auth/AuthProvider';
import { theme } from './theme';

const ContactPage = lazy(() => import('./pages/public/contact/ContactPage'));
const DocsPage = lazy(() => import('./pages/public/docs/DocsPage'));
const DownloadPage = lazy(() => import('./pages/public/download/DownloadPage'));
const HomePage = lazy(() => import('./pages/public/home/HomePage'));
const LinkExpiredPage = lazy(() => import('./pages/public/link-expired/LinkExpiredPage'));
const LoginPage = lazy(() => import('./pages/public/login/LoginPage'));
const NotFoundPage = lazy(() => import('./pages/public/not-found/NotFoundPage'));
const PricingPage = lazy(() => import('./pages/public/pricing/PricingPage'));
const RegisterPage = lazy(() => import('./pages/public/register/RegisterPage'));
const ResendPage = lazy(() => import('./pages/public/resend/ResendPage'));
const ResetPasswordPage = lazy(() => import('./pages/public/reset-password/ResetPasswordPage'));
const TermsPage = lazy(() => import('./pages/public/terms/TermsPage'));
const VerifiedPage = lazy(() => import('./pages/public/verified/VerifiedPage'));

const AccountPage = lazy(() => import('./pages/auth/account/AccountPage'));
const ChangePasswordPage = lazy(() => import('./pages/auth/change-password/ChangePasswordPage'));

// TODO
const FullHeightLoader = () => {
  return <div></div>;
};

function App() {
  // TODO: REMOVE WHEN LAUNCHING
  /*if (window.location.host !== 'localhost:3000' && window.location.search !== '?abc') {
    return <p>Coming soon...</p>;
  }*/

  return (
    <Grommet theme={theme} style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <BrowserRouter>
        <AuthProvider>
          <SubscriptionProvider>
            <Page flex>
              <AppHeader />
              <Box flex>
                <Suspense fallback={<FullHeightLoader />}>
                  <Routes>
                    <Route index element={<HomePage />} />
                    <Route path={RoutePaths.contact} element={<ContactPage />} />
                    <Route path={RoutePaths.docs} element={<DocsPage />} />
                    <Route path={RoutePaths.download} element={<DownloadPage />} />
                    <Route path={RoutePaths.linkExpired} element={<LinkExpiredPage />} />
                    <Route path={RoutePaths.pricing} element={<PricingPage />} />
                    <Route path={RoutePaths.terms} element={<TermsPage />} />
                    <Route path={RoutePaths.verified} element={<VerifiedPage />} />
                    <Route
                      path={RoutePaths.account}
                      element={
                        <AuthGuard>
                          <AccountPage />
                        </AuthGuard>
                      }
                    />
                    <Route
                      path={RoutePaths.changePassword}
                      element={
                        <AuthGuard>
                          <ChangePasswordPage />
                        </AuthGuard>
                      }
                    />
                    <Route
                      path={RoutePaths.login}
                      element={
                        <DisallowAuthGuard>
                          <LoginPage />
                        </DisallowAuthGuard>
                      }
                    />
                    <Route
                      path={RoutePaths.register}
                      element={
                        <DisallowAuthGuard>
                          <RegisterPage />
                        </DisallowAuthGuard>
                      }
                    />
                    <Route
                      path={RoutePaths.resend}
                      element={
                        <DisallowAuthGuard>
                          <ResendPage />
                        </DisallowAuthGuard>
                      }
                    />
                    <Route
                      path={RoutePaths.reset}
                      element={
                        <DisallowAuthGuard>
                          <ResetPasswordPage />
                        </DisallowAuthGuard>
                      }
                    />
                    <Route path='*' element={<NotFoundPage />} />
                  </Routes>
                </Suspense>
              </Box>
              <AppFooter />
            </Page>
          </SubscriptionProvider>
        </AuthProvider>
      </BrowserRouter>
    </Grommet>
  );
}

export default App;
