import { Button, DropButton, Header, Nav, PageContent, ResponsiveContext } from 'grommet';
import { Menu as MenuIcon } from 'grommet-icons';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { RoutePaths, useAuth } from '../../lib';
import { colors } from '../../theme';
import { AnchorLink } from '../anchor-link';
import { Logo } from '../logo';

export const AppHeader = () => {
  const { session, logout } = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <Header
        background='white'
        pad={{ horizontal: '0', vertical: 'medium' }}
        justify='center'
        elevation='medium'
        style={{ zIndex: '1' }} // Put shadow on top of banner image
      >
        <PageContent flex={true} direction='row' align='center' justify='between'>
          <Link to='/' style={{ display: 'block', lineHeight: 0 }}>
            <Logo />
          </Link>

          <ResponsiveContext.Consumer>
            {(responsive) =>
              responsive === 'small' ? (
                <DropButton
                  icon={<MenuIcon color='text' />}
                  dropAlign={{ top: 'bottom', right: 'right' }}
                  open={menuOpen}
                  onClick={() => setMenuOpen(!menuOpen)}
                  onClose={() => setMenuOpen(false)}
                  onOpen={() => setMenuOpen(true)}
                  dropContent={
                    <Nav direction='column' flex justify='center' pad='large'>
                      <AnchorLink
                        to={RoutePaths.download}
                        label='Download'
                        color='text'
                        onClick={() => setMenuOpen(false)}
                      />
                      <AnchorLink
                        to={RoutePaths.docs}
                        label='Docs'
                        color='text'
                        onClick={() => setMenuOpen(false)}
                      />
                      <AnchorLink
                        to={RoutePaths.pricing}
                        label='Pricing'
                        color='text'
                        onClick={() => setMenuOpen(false)}
                      />
                      <AnchorLink
                        to={RoutePaths.contact}
                        label='Contact'
                        color='text'
                        onClick={() => setMenuOpen(false)}
                      />
                      {!session && (
                        <AnchorLink
                          to={RoutePaths.login}
                          label='Login'
                          color='text'
                          onClick={() => setMenuOpen(false)}
                        />
                      )}
                      {!session && (
                        <AnchorLink
                          to={RoutePaths.register}
                          label='Try For Free'
                          color='text'
                          onClick={() => setMenuOpen(false)}
                        />
                      )}
                      {session && (
                        <AnchorLink
                          to={RoutePaths.account}
                          label='Account'
                          color='text'
                          onClick={() => setMenuOpen(false)}
                        />
                      )}
                      {session && (
                        <Button
                          color='text'
                          style={{ fontWeight: '500' }}
                          onClick={() => {
                            setMenuOpen(false);
                            logout();
                          }}
                        >
                          Sign Out
                        </Button>
                      )}
                    </Nav>
                  }
                />
              ) : (
                <>
                  <Nav direction='row' flex justify='center'>
                    <AnchorLink to={RoutePaths.download} label='Download' color='text' />
                    <AnchorLink to={RoutePaths.docs} label='Docs' color='text' />
                    <AnchorLink to={RoutePaths.pricing} label='Pricing' color='text' />
                    <AnchorLink to={RoutePaths.contact} label='Contact' color='text' />
                  </Nav>
                  {!session && (
                    <Nav direction='row' align='center' justify='end' width='small'>
                      <AnchorLink to={RoutePaths.login} label='Login' color='text' />
                      <AnchorLink
                        to={RoutePaths.register}
                        label='Try For Free'
                        style={{
                          display: 'inline-block',
                          backgroundColor: colors.brand,
                          color: '#ffffff',
                          padding: '8px',
                          borderRadius: '4px',
                        }}
                      />
                    </Nav>
                  )}
                  {session && (
                    <Nav direction='row' align='center' justify='end' width='small'>
                      <AnchorLink to={RoutePaths.account} label='Account' color='text' />
                      <Button
                        onClick={() => {
                          logout();
                        }}
                      >
                        Sign Out
                      </Button>
                    </Nav>
                  )}
                </>
              )
            }
          </ResponsiveContext.Consumer>
        </PageContent>
      </Header>
    </>
  );
};
