import { FormField, Select } from 'grommet';

interface Props {
  disabled?: boolean;
}

export enum CategoryType {
  General,
  BugReport,
  FeatureRequest,
  PlantRequest,
}

const options = [
  {
    id: CategoryType.General,
    label: 'General',
  },
  {
    id: CategoryType.BugReport,
    label: 'Report a bug',
  },
  {
    id: CategoryType.FeatureRequest,
    label: 'Request a feature',
  },
  {
    id: CategoryType.PlantRequest,
    label: 'Request a plant or variety to be added',
  },
];
export const ContactCategoryField = (props: Props) => {
  return (
    <FormField name='category' htmlFor='categoryField' label='Category'>
      <Select
        id='categoryField'
        name='category'
        placeholder='Choose category'
        disabled={props.disabled}
        options={options}
        labelKey='label'
        valueKey={{ key: 'id', reduce: true }}
      />
    </FormField>
  );
};
