import { FormField, TextInput } from 'grommet';

interface Props {
  disabled?: boolean;
}

export const FirstNameField = (props: Props) => {
  return (
    <FormField name='firstName' htmlFor='firstNameField' label='First Name'>
      <TextInput
        id='firstNameField'
        name='firstName'
        maxLength={50}
        autoCapitalize='none'
        placeholder='Enter first name'
        disabled={props.disabled}
      />
    </FormField>
  );
};
