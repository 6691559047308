import { FormField, TextInput } from 'grommet';
import { Lock } from 'grommet-icons';

interface Props {
  disabled?: boolean;
}

export const PasswordField = (props: Props) => {
  return (
    <FormField name='password' htmlFor='passwordField' label='Password'>
      <TextInput
        id='passwordField'
        name='password'
        type='password'
        maxLength={50}
        placeholder='Enter password'
        disabled={props.disabled}
        icon={<Lock />}
      />
    </FormField>
  );
};
