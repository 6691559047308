export const RoutePaths = {
  account: '/account',
  changePassword: '/change-password',
  contact: '/contact',
  docs: '/docs',
  download: '/download',
  linkExpired: '/link-expired',
  login: '/login',
  pricing: '/pricing',
  register: '/register',
  resend: '/resend',
  reset: '/reset',
  terms: '/terms',
  verified: '/verified',
};
