import { Navigate } from 'react-router-dom';

import { useAuth } from './use-auth';
import { RoutePaths } from '../routes';

interface Props {
  children: React.ReactElement;
}
// prevent page access when a session is present e.g. login page, register page etc
export const DisallowAuthGuard = (props: Props) => {
  const { session } = useAuth();

  return session ? <Navigate to={RoutePaths.account} replace /> : props.children;
};
