import { Footer, PageContent, Text } from 'grommet';

export const AppFooter = () => {
  return (
    <Footer
      background='brand'
      pad={{ vertical: 'medium' }}
      justify='center'
      style={{ fontSize: 'small' }}
    >
      <PageContent>
        <Text size='small'>© Copyright 2024 Croppa Pty Ltd</Text>
      </PageContent>
    </Footer>
  );
};
