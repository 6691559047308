import { FormField, TextInput } from 'grommet';

interface Props {
  disabled?: boolean;
}

export const EmailField = (props: Props) => {
  return (
    <FormField name='email' htmlFor='emailField' label='Email'>
      <TextInput
        id='emailField'
        name='email'
        maxLength={50}
        autoCapitalize='none'
        placeholder='Enter email address'
        disabled={props.disabled}
      />
    </FormField>
  );
};
