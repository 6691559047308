import { ThemeType } from 'grommet';

export const colors = {
  brand: '#439F15',
  focus: 'accent-1',
};

export const theme: ThemeType = {
  global: {
    colors,
    font: {
      family: 'Open Sans, sans-serif',
      size: '16px',
      height: '18px',
    },
    input: {
      font: {
        weight: 500,
      },
    },
  },
  anchor: {
    fontWeight: 500,
  },
  formField: {
    border: {
      side: 'all',
    },
    round: 'xsmall',
    label: { margin: { horizontal: '0px', vertical: 'xsmall' } },
  },
  notification: {
    toast: {
      time: 10000,
    },
  },
  paragraph: {
    medium: {
      size: '16px',
      height: '20px',
    },
  },
  text: {
    medium: {
      size: '16px',
      height: '20px',
    },
  },
};
