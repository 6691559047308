import { useLocation } from 'react-router-dom';

interface PossibleHashValues {
  error?: string;
  error_code?: string;
  error_description?: string;
}

export const useRedirectHashParams = () => {
  // Could be error landing on this page e.g /verified#error=unauthorized_client&error_code=401&error_description=Email+link+is+invalid+or+has+expired
  const { hash } = useLocation();
  const hashParts = extractHashParts(hash);

  return hashParts;
};

const extractHashParts = (hashString: string): PossibleHashValues => {
  // strip out leading hashtag if present, and split into parts
  const keyValuePairs = hashString.replace('#', '').split('&');

  const result: Record<string, string> = {};

  for (const pair of keyValuePairs) {
    const [key, value] = pair.split('=');
    if (key && value) {
      result[key] = decodeURIComponent(value).replaceAll('+', ' ');
    }
  }

  return result;
};
