import { FormField, TextArea } from 'grommet';

interface Props {
  disabled?: boolean;
}

export const MessageField = (props: Props) => {
  return (
    <FormField name='message' htmlFor='messageField' label='Message'>
      <TextArea
        id='messageField'
        name='message'
        maxLength={1000}
        autoCapitalize='none'
        placeholder='Enter your message'
        disabled={props.disabled}
      />
    </FormField>
  );
};
