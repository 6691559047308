import { createRoot } from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';

const container = document.getElementById('app');
const root = createRoot(container!);
root.render(<App />);
reportWebVitals(sendToVercelAnalytics);
